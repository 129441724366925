<template>
    <div class="is-width-100">

        <PageHead title="Super Admin" />

        <Dialog title="Create New Shop" :open="addShopModalIsOpen" @close="addShopModalIsOpen = false;"
            v-slot="{ onClose }">
            <ShopForm @close="onClose" />
        </Dialog>

        <Dialog :title="'Managing Admins for ' + manageAdminsShop?.name" :open="manageAdminsModalIsOpen"
            @close="manageAdminsModalIsOpen = false" v-slot="{ onClose }">
            <ShopAdminForm :shop-id="manageAdminsShop?.id ?? -1" @close="onClose" />
        </Dialog>

        <div class="box is-flex is-flex-direction-column is-width-fill is-height-fill">

            <div class="level is-align-items-center">
                <div class="level-left">
                    <div class="level-item">
                        <h1 class="box-title is-marginless">
                            Shops Config
                        </h1>
                    </div>
                </div>

                <div class="level-right">
                    <div class="level-item">
                        <button class="button is-warning" @click="addShopModalIsOpen = true;">
                            <span class="icon is-small">
                                <Icon :icon="mdiPlus" />
                            </span>
                            <span>
                                New Shop
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <template v-for="shop in props.shops">
                <Link :href="route('shop.show', { shop: shop.slug })"
                    class="block is-flex is-align-items-center has-gap-large has-text-white has-background-primary-10 py-3 px-4"
                    style="border-radius: .5rem;">

                <LoadingImg :src="shop.logoUrl" style="width: 7rem; height: 7rem; border-radius: .5rem;" />
                <div class="is-flex is-flex-direction-column has-gap-large is-width-100">
                    <div class="is-flex is-flex-direction-column has-gap-small is-flex-grow-1">
                        <div class="is-size-4 is-size-5-mobile has-text-weight-semibold">
                            {{ shop.name }}
                        </div>
                        <p>{{ shop.description }}</p>
                        <!-- <button class="button is-primary is-width-fit mt-2"
                            @click.prevent="openManageAdminsModal(shop.id)">Manage Admins</button> -->
                    </div>
                    <Link :href="route('admin.shops.show', { shop: shop.slug })" class="button is-primary is-height-100 is-width-fit">
                        Shop Admin Panel
                    </Link>
                </div>
                </Link>
            </template>

        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { mdiPlus } from '@mdi/js';
import Dialog from '@/Components/Dialog.vue';
import ShopForm from '@/Components/admin-shop/ShopForm.vue';
import ShopAdminForm from '@/Components/admin-shop/ShopAdminForm.vue';
import Icon from '@/Components/Icon.vue';
import { type Shop } from '@/Types/Shop';
import { Link } from '@inertiajs/vue3';
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import PageHead from '@/Components/PageHead.vue';
import { Item } from '@/Types/Item';
import LoadingImg from '@/Components/LoadingImg.vue';

const addShopModalIsOpen = ref(false);
const manageAdminsModalIsOpen = ref(false);
const shops = ref<Shop[]>([]);
const manageAdminsShop = ref<Shop | null>(null);

const props = defineProps<{
    shops?: Shop[];
    itemsByShop?: Record<number, Item[]>;
}>();

defineOptions({
    layout: [Base, Default]
});

function openManageAdminsModal(shopId: number) {
    const shop = shops.value.find(s => s.id === shopId) as Shop;
    manageAdminsShop.value = shop;
    manageAdminsModalIsOpen.value = true;
}

</script>
