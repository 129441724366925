<template>
    <div class="is-flex is-flex-direction-column is-width-100">
        <PageHead title="Order" />

        <div class="box">
            <div class="box-title block">
                Order Placed {{ dateCreated }}
            </div>

            <div class="block is-flex is-flex-direction-column is-height-fit is-width-100" style="gap: 2.5rem;">

                <div class="is-flex is-align-items-center has-gap-large" v-for="lineItem in order.lineItems">
                    <LoadingImg :src="lineItem.imageUrl" class="item-thumb" width="100" height="100" />
                    <div class="is-flex is-flex-direction-column is-align-items-flex-start has-gap-small">
                        <div class="is-size-4 is-size-5-mobile has-text-weight-semibold">{{ lineItem.item.title }}</div>
                        <div class="is-size-5 is-size-6-mobile">Quantity: {{ lineItem.quantity }}</div>
                        <div class="is-size-5 is-size-6-mobile">Price: ${{ lineItem.price.toFixed(2) }} {{ lineItem.quantity > 1 ? "/each" : "" }}</div>
                        <div class="is-flex is-align-items-center is-flex-wrap-wrap has-gap-small">
                            <div class="tag is-primary is-uppercase is-size-6" v-for="variant in lineItem.variants">
                                {{ variant.variantName }}
                            </div>
                        </div>
                    </div>
                </div>

                <OrderStatus :status="order.status" />

                <div class="is-flex is-flex-wrap-wrap has-text-link px-4 py-3 has-background-primary-15"
                    style="column-gap: 8rem; row-gap: 1.5rem; border-radius: 1rem;">
                    <div class="is-size-6">
                        <div class="is-size-5 mb-1">Cost Summary</div>
                        <div>Subtotal: ${{ order.subtotal.toFixed(2) }}</div>
                        <div>Sales Tax: ${{ order.salesTax.toFixed(2) }}</div>
                        <div class="is-size-5 mt-1">Total: ${{ totalCost }}</div>
                    </div>
                    <div class="is-size-6">
                        <div class="is-size-5 mb-1">Shipping To</div>
                        <div>{{ order.shippingFirstName }} {{ order.shippingMiddleName }} {{ order.shippingLastName }}
                        </div>
                        <div>{{ order.shippingAddress1 }}</div>
                        <div v-if="order.shippingAddress2">{{ order.shippingAddress2 }}</div>
                        <div>{{ order.shippingCity }}, {{ order.shippingState }} {{ order.shippingPostalCode }}</div>
                        <div>{{ order.shippingCountry }}</div>
                    </div>
                    <div class="is-size-6" v-if="order.shipments.length === 0">
                        <div class="is-size-5 mb-1">Shipping Details</div>
                        <div>Pending</div>
                    </div>
                    <div class="is-size-6" v-else v-for="(shipment, i) in order.shipments">
                        <div class="is-size-5 mb-1">Shipping Details {{ order.shipments.length > 1 ? i : '' }}</div>
                        <div>Carrier: {{ shipment.carrier }}</div>
                        <div>Service: {{ shipment.service }}</div>
                        <div>Tracking Number: <a :href="shipment.trackingUrl" class="is-underlined">{{
                            shipment.trackingNumber }}</a></div>
                    </div>
                </div>
            </div>

            <div class="is-size-6 has-text-link-60">Order ID: {{ order.id }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoadingImg from "@/Components/LoadingImg.vue";
import OrderStatus from "@/Components/OrderStatus.vue";
import PageHead from "@/Components/PageHead.vue";
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import { Order } from "@/Types/Order";
import { computed, onMounted } from "vue";

defineOptions({
    layout: [Base, Default]
});

const props = defineProps<{
    order: Order;
}>();

const dateCreated = computed(() => {
    return new Date(props.order.dateCreated).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
});

const totalCost = computed(() => {
    return (props.order.subtotal + props.order.salesTax).toFixed(2);
});

</script>

<style lang="scss" scoped>
.item-thumb {
    width: 6rem;
    height: 6rem;
    border-radius: 1rem;
}
</style>
