<template>
    <div class="is-width-fill">
        <PageHead :title="shop.name +  ' Admin'" :description="shop.description" :image="shop.bannerUrl" />

        <Dialog title="Create New Item" :open="addItemModalIsOpen" @close="addItemModalIsOpen = false"
            v-slot="{ onClose }">
            <ItemForm :shop-id="shop.id" @close="onClose" @saved="shop.refresh" />
        </Dialog>

        <Dialog :title="`Editing ${editItem?.title}`" :open="editItemModalIsOpen" @close="editItemModalIsOpen = false"
            v-slot="{ onClose }">
            <ItemForm @close="onClose" @saved="shop.refresh" :shop-id="shop.id" :item="JSON.parse(JSON.stringify(editItem))" :edit="true" />
        </Dialog>

        <Dialog :title="`Editing ${shop.name} Categories`" :open="editCategoryModalIsOpen"
            @close="editCategoryModalIsOpen = false" v-slot="{ onClose }">
            <ShopCategoryForm :shop-id="shop.id" @close="onClose" close="onClose" />
        </Dialog>

        <Dialog :title="`Editing ${shop.name}`" :open="editShopModalIsOpen" @close="editShopModalIsOpen = false"
            v-slot="{ onClose }">
            <ShopForm :shop="shop" @close="onClose" close="onClose" />
        </Dialog>

        <Dialog :title="`Editing images for item ${editItem?.title}`" :open="editItemImagesModalIsOpen" @close="editItemImagesModalIsOpen = false"
            v-slot="{ onClose }">
            <ItemImagesForm :shop-id="shop.id" :item="editItem" @close="onClose" @saved="shop.refresh" />
        </Dialog>

        <Dialog :title="`Editing ${shop.name} Goals`" :open="editShopGoalModalIsOpen" @close="editShopGoalModalIsOpen = false"
            v-slot="{ onClose }">
            <ShopGoalForm :shop="shop" @close="onClose" close="onClose" />
        </Dialog>

        <div class="box">
            <h1 class="box-title">
                Config
            </h1>
            <div class="is-flex is-flex-wrap-wrap is-align-items-center has-gap-small">
                <button class="button is-primary" @click="editShopModalIsOpen = true;">
                    Shop Config
                </button>
                <button class="button is-primary" @click="editCategoryModalIsOpen = true;">
                    Category Config
                </button>
                <button class="button is-primary" @click="editShopGoalModalIsOpen = true;">
                    Shop Goals Config
                </button>
                <button class="button is-primary" @click="addItemModalIsOpen = true;">
                    Create New Item
                </button>
            </div>
        </div>

        <div class="box is-flex is-flex-direction-column is-width-fill">

            <h1 class="box-title">
                Items
            </h1>

            <div v-if="shop.items.length === 0 && !shop.loading" class="message is-warning">
                <div class="message-body">No items found</div>
            </div>

            <div v-else-if="shop.loading">
                <div v-for="i in 3" class="skeleton-block"></div>
            </div>

            <template v-else>
                <table class="table is-bordered is-hidden-touch" style="position: relative;">
                    <thead class="has-background-primary-25" style="position: sticky; top: 0; z-index: 10;">
                        <tr>
                            <th>Item</th>
                            <th>Desc.</th>
                            <th>Price</th>
                            <th>Variants</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody class="has-background-primary-05">
                        <tr v-for="item in shop.items">
                            <td>
                                <Link :href="route('shop.items.show', { shop: shop.slug, item: item.slug })">{{ item.title }}</Link>
                                <br>
                                <span v-if="item.category" class="tag is-primary is-uppercase">{{
                                    item.category
                                }}</span>
                            </td>
                            <td>{{ truncateDescription(item.description) }}</td>
                            <td>${{ item.price.toFixed(2) }}</td>
                            <td>
                                <span v-for="variant in item.variants" :key="variant.id"
                                    class="tag is-primary is-medium mr-1 mb-1">
                                    <span style="text-transform: capitalize;">{{ variant.type }}</span>:
                                    {{ variant.variantName }}
                                </span>
                            </td>
                            <td style="width: 0">
                                <button class="button is-outlined is-warning mb-2 is-width-100"
                                    @click="openEditItem(item)">
                                    Edit
                                </button>
                                <button class="button is-outlined is-warning mb-2 is-width-100" @click="openEditItemImages(item)">
                                    Images
                                </button>
                                <button class="button is-ghost has-text-danger is-width-100 is-small mt-2" @click="deleteItem(item)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="is-hidden-desktop">
                    <div class="box is-flex is-flex-direction-column has-gap-small" v-for="item in shop.items">
                        <div class="is-size-4">{{ item.title }}
                            <span v-if="item.category" class="tag is-primary is-uppercase">{{ item.category
                                }}</span>
                        </div>
                        <div class="is-size-6">Description: {{ truncateDescription(item.description) }}</div>
                        <div class="is-size-6">Price: ${{ item.price.toFixed(2) }}</div>
                        <div class="is-size-6 is-flex is-flex-wrap-wrap has-gap-small">Variants:
                            <span v-for="variant in item.variants" :key="variant.id" class="tag is-primary">
                                <span style="text-transform: capitalize;">{{ variant.type }}</span>:
                                {{ variant.variantName }}
                            </span>
                        </div>
                        <div class="is-flex is-flex-wrap-wrap has-gap-small mt-2">
                            <button class="button is-small is-warning" @click="openEditItem(item)">
                                Edit
                            </button>
                            <button class="button is-small is-danger" @click="deleteItem(item)">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import ShopCategoryForm from '@/Components/admin-shop/ShopCategoryForm.vue';
import ItemForm from '@/Components/Item/ItemForm.vue';
import type { Item } from '@/Types/Item';
import { useShopStore } from "@/Stores/ShopStore";
import { onMounted, ref } from "vue";
import { addItemModalIsOpen } from './addItemModalIsOpen';
import { Link, router, usePage } from "@inertiajs/vue3";
import Dialog from "@/Components/Dialog.vue";
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import ShopForm from '@/Components/admin-shop/ShopForm.vue';
import ItemImagesForm from '@/Components/Item/ItemImagesForm.vue';
import PageHead from '@/Components/PageHead.vue';
import ShopGoalForm from '@/Components/admin-shop/ShopGoalForm.vue';

const shop = useShopStore();
const editItem = ref<Item | undefined>(undefined);
const editItemModalIsOpen = ref(false);
const editItemImagesModalIsOpen = ref(false);
const editCategoryModalIsOpen = ref(false);
const editShopModalIsOpen = ref(false);
const editShopGoalModalIsOpen = ref(false);

const page = usePage();

defineOptions({
    layout: [Base, Default]
});

onMounted(async () => {
    await shop.initialize(page.props.shopId);
});

function openEditItem(item: Item) {
    editItem.value = item;
    editItemModalIsOpen.value = true;
}

function openEditItemImages(item: Item) {
    editItem.value = item;
    editItemImagesModalIsOpen.value = true;
}

function deleteItem(item: Item) {
    router.delete(route('admin.shops.items.delete', { shop: shop.slug, item: item.slug }), {
        onSuccess: () => {
            shop.deleteItem(item.id);
        },
        onError: () => {
            //
        },
        onStart: () => {
            //
        },
        onFinish: () => {
            //
        }
    });
}

function truncateDescription(description: string) {
    return description.length > 80 ? description.slice(0, 80).trim() + '...' : description;
}

</script>

<style lang="scss">
td {
    max-width: 6rem;
}
</style>
