<template>
    <div class="box is-height-fit">
        <PageHead title="Login" />

        <div class="box-title">
            Login
        </div>

        <div>
            <form @submit.prevent="handleSubmit" novalidate>
                <Field placeholder="Email" type="email" v-model="form.email" :disabled="isLoading" :error="form.errors.email">
                    <template #iconLeft>
                        <Icon :icon="mdiEmail" />
                    </template>
                </Field>
                <Field placeholder="Password" type="password" v-model="form.password" :disabled="isLoading" :error="form.errors.password">
                    <template #iconLeft>
                        <Icon :icon="mdiLock" />
                    </template>
                </Field>

                <div class="is-flex is-align-items-center is-justify-content-space-between">
                    <div class="is-size-7">
                        <Link :href="route('password.request')">
                            Forgot Password
                        </Link>
                    </div>
                    <div>
                        <button type="submit" class="button is-primary" :class="{'is-loading': isLoading}">
                            <span class="icon is-small">
                                <Icon :icon="mdiLogin" />
                            </span>
                            <span>
                                Login
                            </span>
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Field from '@/Components/common/Field.vue';
import Icon from '@/Components/Icon.vue';
import { mdiEmail, mdiLock, mdiLogin } from '@mdi/js'
import { useForm } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import PageHead from '@/Components/PageHead.vue';

defineOptions({
    layout: [Base, Default],
})

const isLoading = ref(false);

const form = useForm({
    email: '',
    password: '',
});

function handleSubmit() {
    form.post(route('login'), {
        onStart: () => {
            isLoading.value = true;
        },

        onFinish: () => {
            isLoading.value = false;
        },
    })
}
</script>

<style lang="scss" scoped>
    @use 'bulma/sass/utilities/mixins';

    .box {
        max-width: 25rem;
        width: 100%;
    }

    @include mixins.mobile {
        .box {
            min-width: 100%;
            width: 100%;
        }
    }
</style>
